    *{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    }

    h1{font-size: 67px;
        line-height: 92px;}

    h2{font-size: 50px;
        line-height: 60px;}

    h3{font-size: 37px;
        line-height: 51px;}

    h4{font-size: 28px;
        line-height: 42px;}

    h5{font-size: 21px;
        line-height: 31px;}

    h6{font-size: 16px;
        line-height: 24px;}

    p{font-size: 12px;
        line-height: 21px;}



h1,h2,h3,h4,h5,h6,p,li,a{
    font-family: 'Roboto', sans-serif;
    color:#D1DFEB;
    text-decoration: none;;
}

.title-text{
    font-family: 'Montserrat', sans-serif;
}


.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
  @media (max-width: 600px){
    h1{font-size: 50px;
        line-height: 92px;}

    h2{font-size: 37px;
        line-height: 50px;}

    h3{font-size: 28px;
        line-height: 42px;}

    h4{font-size: 21px;
        line-height: 31px;}

    h5{font-size: 16px;
        line-height: 24px;}

    h6{font-size: 12px;
        line-height: 18px;}

    p{font-size: 8px;
        line-height: 12px;}
       
       



}
@media (min-width: 1368px){
    h1{font-size: 72px;
        line-height: 92px;}

    h2{font-size: 67px;
        line-height: 60px;}

    h3{font-size: 50px;
        line-height: 51px;}

    h4{font-size: 37px;
        line-height: 42px;}

    h5{font-size: 28px;
        line-height: 31px;}

    h6{font-size: 21px;
        line-height: 24px;}

    p{font-size: 16px;
        line-height: 21px;}

}