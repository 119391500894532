#popUp{
    background: linear-gradient(0deg, rgba(62,79,94,1) 0%, rgba(12,16,19,1) 100%);
    height: 90vh;
    width: 40%;
    border-radius: 8px;
    box-shadow: -3px 7px 8px rgba(0, 0, 0, 0.30);
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 2000;
    left: 30% ;
    top: -1000px;
    flex-direction: column;
    justify-content: space-between;
    
}
.popUpContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30%;
   

}
.popUpContent h5{
    text-align: center;
    margin-top: 2vh;
    margin-bottom: 10vh;
    font-weight: 400;
    width: 90%;
}
.popUpContent p{
    text-align: center;
}
.buttonGroup{
    flex-direction: column;
    align-items: center;
    justify-self: flex-start;
    display: flex;         
}
.buttonGroup p{

    font-size: 16px;
    justify-self: flex-start;   
    margin: 5% auto;
   
    
}
.buttonDirect{
    background-color: #E39F5B;
    border-radius: 16px;
    height: 8vh;
    margin-bottom: 13vh;
    width: 30vw;
    margin-bottom: 0;
}
.buttonDirect a{
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    
}
.buttonDirect span{
    color:#2D3A45;
    font-size: 14px;
    margin-right: 8px;
    font-weight: 900;
}
.iconGroup{
    width: 18vh;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}
#singlePopP{
    margin-top: 15%;
}
@media (max-width: 600px){

    #popUp{
        width: 95%;
        left: 2.5%;
        height: 75%;
        min-height: 480px;
    }
    .buttonDirect{
        width: 50vw;
    }
    .buttonGroup p{
        font-size: 16px;
        margin: 10% auto;

    }
    #singlePopP{
        margin-top: 15%;
    }
}