body{
    background-color: #3E4F5E;
    /* overflow-x:hidden; */
}
.headerComoChegar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#coverComoChegar{
    width: 100%;
    height: 65vh;
    position: absolute;
    z-index: -1000;
    
    
    
    
}
#LogoComoChegar{
margin-top: 16px;
-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    
}
.localização{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
#headerTitle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#headerTitle h4{
    margin-top: 32px;
    -webkit-animation: tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.2s alternate both;
    animation: tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.2s alternate both;
    font-family: 'Montserrat', "Roboto", sans-serif;
    text-shadow: -3px 7px 8px rgba(0, 0, 0, 0.30);

}
.mapContent{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20vw;
    margin-top: 5vh;
    width: auto;
    
}
.mapContent h6{
    width: 20vw;
    text-align: left;
    margin-left: 1vw;
    align-self: flex-start;
    margin-top: 2vh;
    text-shadow: none;
    font-weight: normal; 
    

}
.ComoChegar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;


}
.ComoChegar h4{
    font-family: 'Montserrat', "Roboto", sans-serif;
    text-shadow: -3px 7px 8px rgba(0, 0, 0, 0.30);
    -webkit-animation: tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.2s alternate both;
    animation: tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) 0.2s alternate both;

    
}
.alignContent{
    display: flex;
    margin: 10vh auto;
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.leftInfo img{
    width: 40vw;
}
.leftInfo h6{
    width: 40vw;
    margin-bottom: 2vh;
    text-shadow: none;
    font-weight: normal;
}
.leftInfo{
    display: flex;
    flex-direction: column;
    margin-right: 7vw;
} 
.rightInfo {
    display: flex;
    flex-direction: column;
    margin-left: 7vw;
}
 

.rightInfo img{
    width: 40vw;
}
.rightInfo h6{
    width: 40vw;
    margin-top: 2vh;
    font-weight: normal;
    text-shadow: none;
    color: #2D3A45;
}
.lastContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.lastContent h6{
    width: 50vw;
    font-weight: normal;
    text-shadow: none;
    margin-bottom:2vh;
    color: #2D3A45;
}
.linkBold{
    color: #2D3A45;

}
.lastContent img{
    width: 50vw;
}
.lastContent p{
    text-align: center;
    font-weight: bold;
    margin-top: 2vh;
    color: #2D3A45;
    font-size: 16px;
}


#button-to-popupComoChegar{
    width: 20vw;
    margin-top: 5vh;
}
#whitePath{
    position: absolute;
    z-index: -1000;
    height: 70%;
    width: 100%;
    background: cover;
}
@media (max-width: 900px){
    .BackButton{
        margin: 0px;
        top: 0;
        position: relative;
    }
    
    iframe{
        width: 90%;
        height: 50vh;
        margin: 0;
    }
    .mapContent{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .mapContent h6{
        width: 90%;
        flex: none;
        text-align: center;
        align-self: center;
    }
    #button-to-popupComoChegar{
        width: 60vw;
    }
    #whitePath{
        height: 43%;
    }
    .alignContent{
        margin-top: 30%;
        height: auto;
    }
    .leftInfo h6{
        margin-bottom: 30%;

    }
    .rightInfo h6{

        margin-top: 30%;
    }
}